<template>
  <div :class="containerClass">
    <page-header
      :title="this.$route.params.id ? 'Edit Program' : 'Add New Program'"
      :container-class="null"
      class="mb-16pt"
    />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group
        label="School*"
        label-for="school"
        label-class="form-label"
        v-if="get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN"
      >
        <v-select
          id="school"
          class="form-control v-select-custom"
          label="name"
          v-model="school"
          :reduce="school => school.id"
          placeholder="Select School"
          :options="allSchools"
          :loading="areSchoolsLoading"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!school" v-bind="attributes" v-on="events" />
          </template>

          <template slot="option" slot-scope="option">
            <div class="d-flex align-items-center">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>
              <span>{{ option.name }}</span>
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.name }}
          </template>
        </v-select>
        <b-form-invalid-feedback :state="!$v.school.required && $v.school.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group label="Image*" label-for="image" label-class="form-label" class="row-align-items-center">
        <b-media class="align-items-center" vertical-align="center">
          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isImgUploading">...</span>
            <b-img :src="program.image" class="img-fluid" width="40" alt="Image" v-else-if="program.image" />
            <i class="fas fa-university fa-lg" v-else></i>
          </fmv-avatar>
          <image-uploader @image="setImage" :isUploading="isImgUploading" :isRequired="!program.image" />
        </b-media>
      </b-form-group>

      <b-form-group
        v-if="!program.video_link"
        label="Promo Video"
        label-for="video"
        label-class="form-label"
        class="row-align-items-center"
      >
        <b-media class="align-items-center" vertical-align="center">
          <b-form-file
            id="video"
            placeholder="Select Video"
            :browse-text="$t('generalMsgs.browse')"
            v-model="uploadFile.video"
            @input="uploadImage('video')"
            :disabled="isVideoUploading"
            accept="video/mp4, video/webm"
          />
        </b-media>
      </b-form-group>
      <b-form-group v-if="program.video_link" label="Promo Video" label-for="classes_video" label-class="form-label">
        <div class="d-flex justify-content-end mb-3">
          <b-btn style="width: 80px" variant="danger" @click="program.video_link = ''"> Remove </b-btn>
        </div>
        <video width="100%" height="200" controls>
          <source :src="program.video_link" type="video/mp4" />
        </video>
      </b-form-group>

      <b-form-group label="Title*" label-for="title" label-class="form-label">
        <b-form-input id="name" placeholder="Enter Program's Title" v-model="program.title" required />
        <b-form-invalid-feedback :state="!$v.program.title.required && $v.program.title.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-alert
        variant="primary"
        :show="get(getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER && program.classesCount"
        class="d-flex flex-wrap"
      >
        <div class="mr-8pt">
          <i style="font-size: 18px" class="material-icons">info</i>
        </div>
        <div class="flex" style="min-width: 180px">
          <small class="text-black-100">
            Program category cannot be changed when a program has associated classes with it.
          </small>
        </div>
      </b-alert>

      <b-form-group label="Program Category*" label-for="type" label-class="form-label">
        <v-select
          id="duration-type"
          class="form-control v-select-custom"
          label="title"
          v-model="program.transition_program_id"
          :reduce="program => program.id"
          placeholder="Select program category"
          :options="transProgramsOptions"
          :disabled="get(getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER && !!program.classesCount"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!program.transition_program_id" v-bind="attributes" v-on="events" />
          </template>
        </v-select>
        <b-form-invalid-feedback
          :state="!$v.program.transition_program_id.required && $v.program.transition_program_id.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group
        v-if="get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN"
        label="Stripe Product Link"
        label-for="customer-product"
        label-class="form-label"
      >
        <b-form-input id="customer-product" placeholder="Enter the stripe product link" v-model="stripeProduct" />
      </b-form-group>

      <b-form-group label="Summary" label-for="summary" label-class="form-label">
        <!-- <b-form-input id="short_review" placeholder="Please Enter Review" v-model="shortReview" /> -->
        <b-form-textarea id="summary" placeholder="Enter program summary." :rows="5" v-model="summary" />
      </b-form-group>

      <b-form-group label="Overview*" label-for="overview" label-class="form-label">
        <editor
          :init="{
            height: 400,
            menubar: 'edit view insert format tools table help',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help',
          }"
          v-model="program.overview"
          placeholder="Enter Overview."
        />
        <b-form-invalid-feedback :state="!$v.program.overview.required && $v.program.overview.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group label-for="email_info" label-class="form-label">
        <label for="email_info" class="form-label">Additional Program Info </label>
        <md-icon
          class="mb-1"
          style="font-size: 16px; color: grey"
          v-b-popover.hover.right
          title="Provided information will be sent to students via emails. You can configure required settings in email templates."
          >info</md-icon
        >
        <editor
          :init="{
            height: 400,
            menubar: 'edit view insert format tools table help',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help',
          }"
          v-model="program.emailInfo"
          placeholder="Enter info."
        />
        <!-- <b-form-invalid-feedback :state="!$v.job.description.required && $v.job.description.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        > -->
      </b-form-group>

      <b-form-group label="Duration Type*" label-for="type" label-class="form-label">
        <v-select
          id="duration-type"
          class="form-control v-select-custom"
          label="text"
          v-model="program.durationType"
          :reduce="type => type.value"
          placeholder="Select delivery type"
          :options="durationTypeOptions"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!program.durationType" v-bind="attributes" v-on="events" />
          </template>
        </v-select>
        <b-form-invalid-feedback
          :state="!$v.program.durationType.required && $v.program.durationType.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group
        v-if="program.durationType"
        :label="`${$t(`durationTypesEn.${program.durationType}`)}*`"
        label-for="duration"
        label-class="form-label"
      >
        <b-form-input
          id="durationType"
          :placeholder="`Enter ${$t(`durationTypesEn.${program.durationType}`)}`"
          v-model="program.duration"
          type="number"
          required
        />
        <b-form-invalid-feedback :state="!$v.program.duration.required && $v.program.duration.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group label="Learning Method*" label-for="type" label-class="form-label">
        <v-select
          id="type"
          class="form-control v-select-custom"
          label="text"
          v-model="program.type"
          :reduce="type => type.value"
          placeholder="Select delivery type"
          :options="typeOptions"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!program.type" v-bind="attributes" v-on="events" />
          </template>
        </v-select>
        <b-form-invalid-feedback :state="!$v.program.type.required && $v.program.type.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group label="Type*" label-for="schedule_type" label-class="form-label" v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          id="schedule_type"
          v-model="program.schedule_type"
          :options="schTypeOptions"
          :aria-describedby="ariaDescribedby"
          required
        ></b-form-radio-group>
      </b-form-group>

      <div class="row" v-if="program.schedule_type === CLASS_SCHEDULE_TYPES.SCHEDULED">
        <b-form-group label="Default Start Time*" label-for="Start Time" label-class="form-label" class="col-md-6">
          <date-picker
            v-model="program.start_time"
            lang="en"
            type="time"
            value-type="HH:mm"
            format="hh:mm a"
            :minute-step="15"
            style="width: 100%"
            :placeholder="'Select default class starting time'"
            :input-class="
              `mx-input ${
                !$v.program.start_time.required && $v.program.start_time.$dirty ? 'form-control is-invalid' : ''
              }`
            "
          ></date-picker>
          <b-form-invalid-feedback
            :state="!$v.program.start_time.required && $v.program.start_time.$dirty ? false : null"
          >
            This field is required.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="Default End Time*" label-for="End Time" label-class="form-label" class="col-md-6">
          <date-picker
            v-model="program.end_time"
            type="time"
            lang="en"
            value-type="HH:mm"
            format="hh:mm a"
            :minute-step="15"
            style="width: 100%"
            :placeholder="'Select default class ending time'"
            :input-class="
              `mx-input ${!$v.program.end_time.required && $v.program.end_time.$dirty ? 'form-control is-invalid' : ''}`
            "
          ></date-picker>
          <b-form-invalid-feedback :state="!$v.program.end_time.required && $v.program.end_time.$dirty ? false : null">
            This field is required.
          </b-form-invalid-feedback>
          <b-form-invalid-feedback
            :state="!$v.program.end_time.afterStartTime && $v.program.end_time.$dirty ? false : null"
          >
            End time must be after start time.
          </b-form-invalid-feedback>
        </b-form-group>
      </div>

      <b-form-group
        label="Self Paced Days"
        label-for="self_paced_days"
        label-class="form-label"
        v-if="['virtual', 'blended'].includes(program.type)"
      >
        <b-form-input
          id="self_paced_days"
          v-model="program.self_paced_days"
          type="number"
          placeholder="Enter self paced days"
        />
      </b-form-group>

      <b-form-group label="Default Hours" label-for="default_hours" label-class="form-label">
        <currency-input
          id="amount"
          v-model="program.default_hours"
          class="form-control"
          placeholder="Enter default hours"
          :allow-negative="false"
          :precision="1"
          :currency="null"
        />
      </b-form-group>
      <b-form-group label-class="form-label">
        <b-form-checkbox v-model="program.pre_enrollment_enabled" name="check-button" switch>
          Enable pre-registration
        </b-form-checkbox>
      </b-form-group>

      <!-- No payout alert for school owners/admins -->
      <div
        v-if="
          !this.$route.params.id &&
            get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SCHOOL &&
            (getPayoutAccountStatus === PAYOUT_ACC_STATUS.UNLINKED ||
              getPayoutAccountStatus === PAYOUT_ACC_STATUS.LINKED)
        "
      >
        <div class="alert alert-warning w-100" role="alert">
          Students will not be able to register even if you mark this program as offering because you haven't added any
          verified payout information yet.
        </div>
      </div>

      <page-separator title="Fee Structure" />

      <b-form-group label-class="form-label">
        <b-form-checkbox v-model="program.hideFee" name="check-button" switch>
          Hide fee on program display?
        </b-form-checkbox>
      </b-form-group>

      <b-form-group
        :label="`${get(getLoggedInUser, 'linked_entity.tuition_fee_name') || 'Tuition Fee'}*`"
        label-for="tuition_fee"
        label-class="form-label"
      >
        <currency-input
          v-model="program.tuition_fee"
          required
          class="form-control"
          placeholder="Enter complete tuition fee"
          :allow-negative="false"
        />
        <b-form-invalid-feedback
          :state="!$v.program.tuition_fee.required && $v.program.tuition_fee.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
        <b-form-invalid-feedback
          :state="!$v.program.tuition_fee.nonZero && $v.program.tuition_fee.$dirty ? false : null"
          >Tuition fee must be greater than 0.</b-form-invalid-feedback
        >
      </b-form-group>
      <!-- <b-form-group label-class="form-label">
        <b-form-checkbox v-model="program.tuition_reg_req" name="check-button" switch>
          Tuition fee required during registration
        </b-form-checkbox>
      </b-form-group> -->

      <b-form-group
        :label="`${get(getLoggedInUser, 'linked_entity.app_fee_name') || 'Application Fee'}*`"
        label-for="application_fee"
        label-class="form-label"
      >
        <currency-input
          v-model="program.application_fee"
          required
          class="form-control"
          placeholder="Enter non-refundable application fee"
          :allow-negative="false"
        />
        <b-form-invalid-feedback
          :state="!$v.program.application_fee.required && $v.program.application_fee.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
        <b-form-invalid-feedback
          :state="!$v.program.application_fee.nonZero && $v.program.application_fee.$dirty ? false : null"
          >Application fee must be greater than 0.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group label="Payment Options*" label-for="tuition-options" label-class="form-label">
        <v-select
          id="tuition-options"
          class="form-control v-select-custom"
          label="text"
          v-model="program.selectedTuition"
          :reduce="type => type.value"
          placeholder="Select payment option"
          @input="reqTuitionFee"
          :options="tuitionFeeOptions"
          :clearable="false"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!program.selectedTuition" v-bind="attributes" v-on="events" />
          </template>
        </v-select>
        <b-form-invalid-feedback
          :state="!$v.program.selectedTuition.required && $v.program.selectedTuition.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $route.params.id ? $t('update') : $t('add') }}</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import Editor from '@tinymce/tinymce-vue';
import { FmvAvatar } from 'fmv-avatar';
import { get } from 'lodash';
import DatePicker from 'vue2-datepicker';
import 'vue-swatches/dist/vue-swatches.css';
import vSelect from 'vue-select';
import Page from '@/components/Page.vue';
import PageHeader from '@/components/Ui/PageHeader.vue';
import {
  USER_ROLES,
  PAYOUT_ACC_STATUS,
  USER_ROLE_TYPES,
  DURATION_TYPES,
  PROGRAM_PAYMENT_OPTIONS,
  CLASS_SCHEDULE_TYPES,
  SCHOOL_TYPES,
} from '@/common/constants';
import { required, requiredIf } from 'vuelidate/lib/validators';

import ImageUploader from '@/components/ImageUploader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  components: {
    PageHeader,
    Editor,
    FmvAvatar,
    DatePicker,
    vSelect,
    ImageUploader,
    PageSeparator,
  },
  extends: Page,

  data() {
    return {
      compressedImage: '',

      title: this.$route.params.id ? 'Edit Program' : 'Add Program',
      tinemceApiKey: process.env.VUE_APP_TINEMCE_API_KEY,
      linkedStatus: true,
      program: {
        title: '',
        overview: '',
        image: '',
        durationType: '',
        duration: null,
        tuition_fee: null,
        application_fee: null,
        type: '',
        start_time: '',
        end_time: '',
        transition_program_id: null,
        tuition_reg_req: false,
        pre_enrollment_enabled: false,
        self_paced_days: 0,
        default_hours: null,
        emailInfo: '',
        selectedTuition: PROGRAM_PAYMENT_OPTIONS.PAY_LATER_NOW,
        video_link: null,
        schedule_type: CLASS_SCHEDULE_TYPES.SCHEDULED,
        classesCount: 0,
        hideFee: false,
      },
      stripeProduct: '',
      school: null,
      areSchoolsLoading: false,
      areTransProgramsLoading: false,
      allSchools: [],
      isLoading: false,
      isImgUploading: false,
      isVideoUploading: false,
      allPrograms: [],
      typeOptions: [
        { value: 'virtual', text: 'Virtual' },
        { value: 'onsite', text: 'Onsite' },
        { value: 'blended', text: 'Hybrid' },
      ],
      durationTypeOptions: [
        { value: 'weeks', text: 'Weeks' },
        { value: 'days', text: 'Days' },
      ],
      tuitionFeeOptions: [
        { value: 'tuition_req', text: 'Tuition fee required during registration' },
        { value: 'pay_later', text: "Allow 'Pay Later' only" },
        { value: 'pay_later_now', text: "Allow Both 'Pay Later' and 'Pay Now'" },
      ],

      transProgramsOptions: [],
      uploadFile: { image: null, video: null },
      uploadPercent: { image: 0, video: 0 },
      uploadCancelTokenSource: { image: null, video: null },
      USER_ROLES,
      USER_ROLE_TYPES,
      PAYOUT_ACC_STATUS,
      DURATION_TYPES,
      summary: '',

      schTypeOptions: [
        { value: 'scheduled', text: 'Scheduled' },
        { value: 'self_paced', text: 'Self-Paced' },
      ],
      CLASS_SCHEDULE_TYPES,
    };
  },
  validations() {
    return {
      school: { required: requiredIf(() => get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN) },
      program: {
        title: { required },
        overview: { required },
        image: { required },
        // video_link: { required },
        duration: { required },
        tuition_fee: {
          required,
          nonZero: value =>
            get(this.getLoggedInUser, 'linked_entity.school_type') !== SCHOOL_TYPES.NATIONAL || value > 0,
        },
        application_fee: {
          required,
          nonZero: value =>
            get(this.getLoggedInUser, 'linked_entity.school_type') !== SCHOOL_TYPES.NATIONAL || value > 0,
        },
        type: { required },
        durationType: { required },
        selectedTuition: { required },

        start_time: { required: requiredIf(() => this.program.schedule_type === CLASS_SCHEDULE_TYPES.SCHEDULED) },
        end_time: {
          required: requiredIf(() => this.program.schedule_type === CLASS_SCHEDULE_TYPES.SCHEDULED),
          afterStartTime: value =>
            !this.program.start_time ||
            !value ||
            moment(value, 'HH:mm').isAfter(moment(this.program.start_time, 'HH:mm')),
        },
        transition_program_id: { required },
      },
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('payoutAcc', ['getPayoutAccountStatus']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        {
          text: 'Programs',
          to: {
            name:
              get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN ? 'sa-manage-programs' : 'manage-programs',
          },
        },
        {
          text: this.$route.params.id ? 'Edit Program' : 'Add Program',
          active: true,
        },
      ];
    },
    isFormLoading() {
      return this.isLoading || this.isImgUploading || this.areSchoolsLoading;
    },
  },

  methods: {
    ...mapActions('program', ['getProgram', 'updateProgram', 'createProgram', 'getProgramImageUploadPresignedUrl']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('school', ['getAllSchools', 'getAllTransPrograms']),
    ...mapActions('fileDownload', ['downloadFile']),
    get,

    setImage(file) {
      this.uploadFile['image'] = file;
      this.uploadImage('image');
    },

    reqTuitionFee() {
      this.program.selectedTuition === PROGRAM_PAYMENT_OPTIONS.TUITION_REQ
        ? (this.program.tuition_reg_req = true)
        : (this.program.tuition_reg_req = false);
    },
    async fetchSchools() {
      this.areSchoolsLoading = true;

      const response = await this.getAllSchools({});
      this.allSchools = response.data;

      this.areSchoolsLoading = false;
    },

    async fetchTransPrograms() {
      this.areTransProgramsLoading = true;

      try {
        const response = await this.getAllTransPrograms({});
        if (response.data.length) {
          this.transProgramsOptions = response.data;
        } else {
          this.transProgramsOptions = [];
          this.program.transition_program_id = null;
        }
      } catch (e) {
        // TODO use if needed
      }

      this.areTransProgramsLoading = false;
    },

    async uploadImage(uploadType) {
      this.uploadCancelTokenSource[uploadType] = axios.CancelToken.source();
      this.isImgUploading = uploadType === 'image';
      this.isVideoUploading = uploadType === 'video';
      try {
        const urlResp = await this.getProgramImageUploadPresignedUrl({
          file_name: this.uploadFile[uploadType].name,
          content_type: this.uploadFile[uploadType].type,
          upload_type: 'images',
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.uploadFile[uploadType],
          config: {
            onUploadProgress: function(progressEvent) {
              this.uploadPercent[uploadType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[uploadType].token,
          },
        });
        if (uploadType === 'image') {
          this.program.image = urlResp.upload_url.split('?')[0];
        } else this.program.video_link = urlResp.upload_url.split('?')[0];
      } catch (error) {
        this.uploadFile[uploadType] = null;
      }

      this.uploadCancelTokenSource[uploadType] = null;
      this.uploadPercent[uploadType] = 0;

      if (uploadType === 'image') this.isImgUploading = false;
      else this.isVideoUploading = false;
    },

    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const data = {
            title: this.program.title,
            overview: this.program.overview,
            image: this.program.image,
            duration: this.program.duration,
            duration_type: this.program.durationType,
            tuition_fee: this.program.tuition_fee,
            application_fee: this.program.application_fee,
            type: this.program.type,
            default_class_start_time:
              this.program.schedule_type === CLASS_SCHEDULE_TYPES.SCHEDULED ? this.program.start_time || null : null,
            default_class_end_time:
              this.program.schedule_type === CLASS_SCHEDULE_TYPES.SCHEDULED ? this.program.end_time || null : null,
            transition_program_id: this.program.transition_program_id,
            additional_prog_info: this.program.emailInfo,
            stripe_prod_link: this.stripeProduct
              ? this.stripeProduct.startsWith('https://')
                ? this.stripeProduct
                : `https://${this.stripeProduct}`
              : '',
            tuition_reg_req: this.program.tuition_reg_req,
            hide_tuition_fee: this.program.hideFee,
            allow_pymnt_option: this.program.selectedTuition,
            pre_enrollment_enabled: this.program.pre_enrollment_enabled,
            summary: this.summary,
            school:
              get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN
                ? this.school
                : this.getLoggedInUser.linked_entity.id,
            self_paced_days: this.program.self_paced_days || 0,
            default_hours: this.program.default_hours,
            video_link: this.program.video_link,
            schedule_type: this.program.schedule_type,
          };

          if (this.$route.params.id) {
            await this.updateProgram({
              id: this.$route.params.id,
              data,
            });
            this.makeToast({ variant: 'success', msg: this.$t('Program Updated!') });
          } else {
            await this.createProgram({
              ...data,
            });
            this.makeToast({ variant: 'success', msg: 'Program Added!' });
          }
          setTimeout(
            () =>
              this.$router.push({
                name:
                  get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN
                    ? 'sa-manage-programs'
                    : 'manage-programs',
              }),
            250
          );
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
      this.isLoading = false;
    },
  },

  async mounted() {
    this.isLoading = true;

    if (get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN) {
      this.fetchSchools();
    }

    try {
      if (this.$route.params.id) {
        const resp = (await this.getProgram(this.$route.params.id)).data;
        this.program.title = resp.title;
        this.program.self_paced_days = resp.self_paced_days;
        this.program.overview = resp.overview;
        this.program.image = resp.image;
        this.program.duration = resp.duration;
        this.program.durationType = resp.duration_type;
        this.program.tuition_fee = resp.tuition_fee;
        this.program.application_fee = resp.application_fee;
        this.program.type = resp.type;
        this.program.start_time = resp.default_class_start_time;
        this.program.pre_enrollment_enabled = resp.pre_enrollment_enabled;
        this.program.end_time = resp.default_class_end_time;
        this.program.transition_program_id = resp.transition_program_id;
        this.stripeProduct = resp.stripe_prod_link;
        this.program.tuition_reg_req = resp.tuition_reg_req;
        this.program.hideFee = resp.hide_tuition_fee;

        this.program.emailInfo = resp.additional_prog_info;
        this.program.schedule_type = resp.schedule_type;
        this.program.video_link = resp.video_link;

        if (resp.allow_pymnt_option === PROGRAM_PAYMENT_OPTIONS.TUITION_REQ || this.program.tuition_reg_req) {
          this.program.tuition_reg_req = true;

          this.program.selectedTuition = PROGRAM_PAYMENT_OPTIONS.TUITION_REQ;
        } else {
          this.program.tuition_reg_req = false;
          this.program.selectedTuition = resp.allow_pymnt_option;
        }

        this.school = get(resp, 'school.id', null);
        this.summary = resp.summary;
        this.program.default_hours = resp.default_hours;
        this.program.classesCount = resp.classes_count;
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      this.$router.push({
        name: get(this.getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN ? 'sa-manage-programs' : 'manage-programs',
      });
    }
    this.fetchTransPrograms();
    this.isLoading = false;
  },
};
</script>
